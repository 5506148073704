import type { ApiData, Metadata } from './types';

import type { Route } from 'nextjs-routes';

import config from 'configs/app';

import getPageOgType from './getPageOgType';

export default function generate<R extends Route>(route: R, apiData?: ApiData<R>): Metadata {

  const title = apiData ? 'RWA.io Testnet Blockchain Explorer' : 'RWA.io Testnet Blockchain Explorer';
  const description = 'RWA.io Builds Its Own Layer-2 Network On Top Of Ethereum, Using A Low-Cost, Secure, Safe, And Private Zero-Knowledge Technology.';

  const pageOgType = getPageOgType(route.pathname);

  return {
    title: title,
    description,
    opengraph: {
      title: title,
      description: pageOgType !== 'Regular page' ? config.meta.og.description : '',
      imageUrl: pageOgType !== 'Regular page' ? config.meta.og.imageUrl : '',
    },
  };
}
